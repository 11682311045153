.message {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.messageText {
  padding: 0 5px;
}
.razielMessage {
  text-align: left;
}

.userMessage {
  /* text-align: right; */
  justify-content: right;
}

.loading:after {
  content: "...";
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis-dot 1s infinite 0.3s;
  animation-fill-mode: forwards;
  width: 1.25em;
  padding-left: 5px;
}

@keyframes ellipsis-dot {
  25% {
    content: "";
  }
  50% {
    content: ".";
  }
  75% {
    content: "..";
  }
  100% {
    content: "...";
  }
}
